import React, { useId } from "react";

const RadioButton = ({ value, label, register = {}, ...props }) => {
  const id = useId()
  return (

    <label className="border card p-3" htmlFor={id}>
      <div className="form-check">

        <input type="radio"
          className="form-check-input input-primary"
          value={value} id={id}
          {...register}
          {...props}
        />

        <span className="form-check-label d-block" >
          <strong className="">
            {label}
          </strong>
        </span>

      </div>
    </label>

  );
};

export default RadioButton;
