import React, { useState } from "react";
import OffCanvas from "../../../components/OffCanvas";
import CreatePatient from "./Patient";
import AddAppointment from "./Appointment";
import { useSelector } from "react-redux";

export default () => {
  const { tab } = useSelector( state => state.reception)

  return (
    <OffCanvas title={ tab === 'appointment' ? "Appointment" : "Payment" }>
      {tab === "appointment"
        ?  <CreatePatient />
        : <AddAppointment />
      }
    </OffCanvas>
  );
};
