
import { combineReducers, configureStore  } from '@reduxjs/toolkit';
import common from './reducers/common';
import auth from './reducers/auth';
import reception from './reducers/reception';


const rootReducer = combineReducers({
    common,
    auth,
    reception,
})

const store = configureStore({
    reducer: rootReducer
})

const { dispatch } = store

export { store, dispatch }