import { useCallback, useEffect, useState } from "react";
import {
  faExpand,
  faBell,
  faBars
} from "@fortawesome/free-solid-svg-icons";

import Appoinment from "../pages/Reception/Appointment";
import Icon from "../components/Elements/Icon";
import FullScreen from "../service/FullScreenService";

import { useDispatch, useSelector } from "react-redux";
import { offcanvasToggle, toggleSidebar, toggleMobileSidebar } from "../store/reducers/common";
import { useDebounce } from "../hooks/useDebounce";

import axios from "axios";
import AutoComplete from "../components/Elements/AutoComplete";

export default () => {
  const dispatch = useDispatch();
  const user = useSelector( state => state.auth.user )

  const [result, setResult] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(document.fullscreen);

  useEffect(() => {
    document.onfullscreenchange = (e) => setIsFullScreen(!isFullScreen)
  }, [ isFullScreen ])

  const fullScreenToggle = () => {
    FullScreen();
  };

  const [searchPatient, loading] = useDebounce( async (search) => {
      if (!search) {
        setResult([]);
        return;
      }
      const response = await axios.get(`/patients`, {
        params: { search },
      });

      setResult(response.data.patients);
    }, 500, []);

    const options = useCallback((patient) =>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='mb-0 text-dark'>
          <p className="mb-0">{patient.UPID}</p>
          <span className="text-muted f-10">{patient.phone}</span>
        </div>
        <p className="mb-0 f-14">{patient.name}</p>
        <p className="mb-0 f-14">{patient.age}/{patient.gender}</p>
      </div>,
    [result])
 

  return (
    <>
      <header className="pc-header">
        <div className="header-wrapper ">
          <div className="w-100 pc-mob-drp">
            <ul className="list-unstyled w-100">

              <li className="pc-h-item pc-sidebar-collapse">
                <a  className="pc-head-link ms-0" onClick={() => dispatch(toggleSidebar())}>
                  <Icon icon={faBars} />
                </a>
              </li>

              <li className="pc-h-item pc-sidebar-popup ">
                <a  className="pc-head-link ms-0" onClick={() => dispatch(toggleMobileSidebar())} >
                  <Icon icon={faBars} />
                </a>
              </li>

              <li className="pc-h-item d-none d-md-inline-flex w-50">

                <div className="col-12 mt-3">
                  <AutoComplete
                        onChange={(value) => searchPatient(value)}
                        loading={loading}
                        options={result}
                        optionValue={({ name }) => name}
                        optionLabel={options}
                        placeholder="Search Patients"
                        position="bottom"
                        className="form-control  hover-effect"
                      />
                </div>
              </li>

              {user.role?.role === "RC" && <li className="pc-h-item d-none-sm">
                <button
                  onClick={() => dispatch(offcanvasToggle())}
                  className="btn btn-primary mx-3"
                >
                  Add Appointment
                </button>
              </li>}

            </ul>
          </div>
          
          <div className="ms-auto">
            <ul className="list-unstyled">
              <li className="pc-h-item mx-2">
                <a
                  className={`pc-head-link me-0 hover-effect  border border-1  ${
                    isFullScreen ? "bg-primary" : "bg-white"
                  }`}
                  onClick={fullScreenToggle}
                  title="Full Screen"
                >
                  <Icon
                    icon={faExpand}
                    className={isFullScreen ? "text-light" : "text-primary"}
                  />
                </a>
              </li>

              <li className="pc-h-item mx-2">
                <a
                  className="pc-head-link  me-0 bg-white border border-1 hover-effect"
                  title="Notification"
                >
                  <Icon icon={faBell} className="text-primary" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      { user?.role?.role === "RC" && <Appoinment />}
    </>
  );
};
