import { createSlice } from "@reduxjs/toolkit"

const receiptionSlice = createSlice({
    name: "reception",

    initialState: {
        patient: {},
        payment: {},
        tab: "appointment"
    },

    reducers: {

        setPatient: (state, acton) => {
            state.patient = acton.payload;
        },

        setPayment: (state, action) => {
            state.payment = action.payload;
        },

        setTab: (state, action) => {
            state.tab = action.payload
        }

    }
})

export const { setPatient, setPayment, setTab } = receiptionSlice.actions

export default receiptionSlice.reducer;