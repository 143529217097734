import axios from "axios";
import React, { useState } from "react";

import { useDebounce } from "../../hooks/useDebounce";

import Search from "../../components/Elements/Search";

import Spinner from "../../components/Elements/Spinner";
import PrimaryBtn from "../../components/Elements/PrimaryBtn";

import { setPatient, setTab } from "../../store/reducers/reception";
import { useDispatch } from "react-redux";

export default () => {
  const [result, setResult] = useState([]);

  const dispatch = useDispatch() 

  const [searchPatient, loading] = useDebounce(
    async (search) => {
      if (!search) {
        setResult([]);
        return;
      }
      const response = await axios.get(`/patients`, {
        params: { search },
      });

      setResult(response.data.patients);
    },
    500,
    []
  );

  const bookAppointment = (patient) =>{
    dispatch(setPatient(patient))
    dispatch(setTab('payment'))
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Search
            placeholder={"Search UPID, Name or Phone"}
            onChange={(e) => searchPatient(e.target.value)}
          />
        </div>
      </div>

      <div className=" d-flex justify-content-center  mt-3">
        <Spinner loading={loading} text="Loading..." className="text-primary" />
      </div>

      {result?.length > 0 && (
        <div className="mt-3">
          <ul className="list-unstyled">
            {result?.map((patient, index) => (
              <li key={index}>
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <p className="mb-0 fs-5">
                      UPID : <strong>{patient.UPID}</strong>
                    </p>
                    <p className="mb-0 fs-5">
                      Name : <strong>{patient.name}</strong>
                    </p>
                  </div>
                  <div className="card-body">
                    <div className=" mb-2 d-flex justify-content-between">
                      <div className="">
                        <p className="mb-0">
                          Age : <strong>{patient.age}</strong>
                        </p>
                        <p className="mb-0">
                          Gender : <strong>{patient.gender}</strong>
                        </p>
                      </div>

                      <div className="">
                        <p className="mb-0">
                          Mobile : <strong>{patient.phone}</strong>
                        </p>
                        <p className="mb-0">
                          Address : <strong>{patient.address.city}</strong>
                        </p>
                      </div>
                    </div>
                    <PrimaryBtn title={"Book Appointment"} onClick={()=>{bookAppointment(patient)}} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
