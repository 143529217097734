import { lazy } from "react"
import Loadable from "../components/Loadable"

import Layout from '../pages/Authentication';
import { Navigate } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";

const Login = Loadable(lazy(() => import('../pages/Authentication/Login')))
const ForgotPassword = Loadable(lazy(() => import('../pages/Authentication/Forgot')))
const NewPassword = Loadable(lazy(() => import('../pages/Authentication/NewPassword')));

export default {
    path: "/",
    element: <ErrorBoundary><Layout /></ErrorBoundary>,
    children: [
        {
            path: "/",
            element: <Login />
        },
        {
            path: "/token/:token",
            element: <NewPassword />
        },
        {
            path: "/forgot-password",
            element: <ForgotPassword />
        },
        { path: '/*', element: <Navigate to="/" />}

    ]
}