import React  from "react";


const Table = ({children, title='Table', header, thead}) => {

  return (
    <>
    <div className="card table-card">
      <div className="card-header">
        {!header &&<div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">{title}</h4>
        </div>}
        {header}
      </div>
      <div className="card-body ">
        <div className="table-responsive">
          <table className="table table-hover" id="pc-dt-simple">
            <thead>
              <tr>
                {thead?.map((t)=><th style={{width:t.width, textAlign:t?.text}}>{t.title}</th>)}
              </tr>
            </thead>
            <tbody>
              {children}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    </>
  );
};

export default Table;
