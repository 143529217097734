import Layouts from "../layouts"
import { Navigate } from 'react-router-dom';
import * as Pages from './Imports'
import ErrorBoundary from "../components/ErrorBoundary";


const role_routes = ( role ) => {
    const commonRoutes = [
        { path:'/patients', element: <Pages.Patients />},
        { path: '/appointments' , element : <Pages.Appointments/>},
        { path:'/settings', element:<Pages.Setting />},
        { path:'/apitesting', element: <Pages.APITesting />},
        { path:'/sample', element: <Pages.Sample />},
        { path: '/*', element: <Navigate to="/" />}
    ]


    switch ( role ) {

        case "RC" : return [
            { path: '/',  element: <Pages.RC_Dashboard /> },
            { path:'/payments', element:<Pages.Payments source={"reception"} />},
            { path:'/reports', element:<Pages.RC_Reports />},            
            { path:'/generate-receipt/:appointmentId', element:<Pages.GenerateReceipt />},
            { path:'/patient/:patientId', element:<Pages.PatientProfile />},
            ...commonRoutes,
        ]

        case 'AC' : return [
            {path : '/Dashboard', element : <Pages.AC_DashBoard/> },
            {path : '/categories', element : <Pages.AC_Categories/> },
            {path : '/expances', element : <Pages.AC_Expances/> },
            {path : '/earning', element : <Pages.AC_Earning/> },
            {path : '/setup', element : <Pages.AC_Setup/> },
            ...commonRoutes,
        ]

        case "SA": return [
            { path: '/',  element: <Pages.SA_Dashboard /> },
            { path: '/users',  element: <Pages.SA_Users /> },
            { path: '/services',  element: <Pages.SA_Services /> },
            ...commonRoutes,
        ]

        case 'DR' : return [
            { path: '/' , element : <Pages.DR_Dashboard/>},
            { path: '/tokens' , element : <Pages.DR_CurrentTokens/>},
            { path: '/payments' , element : <Pages.Payments source={''} />},
            { path:'/prescription/:appointmentId', element: <Pages.DR_Perscription />},
            ...commonRoutes,
        ]

        case 'JDR' : return [
            { path: '/' , element : <Pages.DR_CurrentTokens/>},
            { path:'/prescription/:appointmentId', element: <Pages.DR_Perscription />},
            ...commonRoutes,
        ]

        case 'PH' : return [
            { path: '/' , element : <Pages.PH_Dashboard/>},
            { path: '/medicines' , element : <Pages.PH_Medicines/>},
            { path: '/add-medicine' , element : <Pages.PH_AddMedicine/>},
            { path: '/update-medicine/:medicineId' , element : <Pages.PH_AddMedicine/>},
            { path: '/payments' , element : <Pages.Payments source={'pharmacy'} />},
            { path: '/invoice/:id' , element : <Pages.PH_Invoice />},
            { path:'/generate-invoice/:appointmentId', element: <Pages.PH_GenerateInvoice />},
            ...commonRoutes,
        ]
        default:
            break;
    }
}

export default (role) => ({
    path: '/',
    element: <ErrorBoundary><Layouts /></ErrorBoundary>,
    children: role_routes(role)
})