import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNotification } from '../../../contexts/Notification'
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '../../../hooks/useDebounce'

import InputBox from '../../../components/Elements/InputBox';
import Spinner from '../../../components/Elements/Spinner';
import Select from '../../../components/Elements/Select';
import axios from '../../../axios';

import { setPayment, setTab } from '../../../store/reducers/reception';
import { offcanvasToggle } from '../../../store/reducers/common';
import { dateFormate } from '../../../Utils';
import { firms, paymentModes } from '../../../constant';



export default () => {

  const [list, setList] = useState({ Doctors: [], Services: [] })
  const { register, handleSubmit, watch, reset, setValue } = useForm({  });

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { patient } = useSelector(state => state.reception)
  const notification = useNotification()
  const service = watch('service');

  const mode = watch('mode')



  useEffect(() => {
    fetchDoctorsServices()
  }, [])


  useEffect(() => {
    const getService = list.Services.find(serv => serv.label == service)
    console.log("getServices", getService);
    
    setValue('amount', mode === 'followUp' ?  0 : getService?.amount )
    setValue("doctorId", getService?.doctorId)
    setValue("firm", getService?.firm)
  }, [service, mode])



  const fetchDoctorsServices = async () => {
    const responseService = await axios.get('/services')
    const responseDoctor = await axios.get('/doctors')

    console.log("ll",responseService);
    

    const services = responseService.data.services?.map((service) =>
      ({ label: service.name, value: service.name, amount: service.amount,firm : service.firm, doctorId : service?.assignedTo?._id })
    )

    setList({
      Doctors: responseDoctor.data.doctors,
      Services: services
    })

    setValue( 'service', services[0].value)
  }

  const [onSubmit, isSubmitLoading] = useDebounce(async (data) => {

    const response = await axios.post('/reception/appointment',
      { ...data, patientId: patient?._id },
    )
    reset({})

    window.socket.emit('new-appointment', {
      appointmentId: response.data?.appointment?._id
    })

    dispatch(setPayment(response.data.payment))
    dispatch(setTab("appointment"))
    dispatch(offcanvasToggle())

    notification.success(response.data.message)
    navigate(`/generate-receipt/${response.data?.appointment?._id}`, { replace: true })


  }, 1500, [patient])



  

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <h4 className='mb-4'>{dateFormate(new Date())}</h4>
          <div className="col-md-12">
            <Select label={'Service'} register={register('service')} list={list.Services} />
          </div>

         
     
          <div className="col-md-6">
            <InputBox label={'Name'} placeholder={'Name'} disabled value={patient?.name || ""} />
          </div>
          <div className="col-md-6">
            <InputBox label={'Amount'} placeholder={'Amount'}  register={register('amount')} disabled />
          </div>

          <div className="col-md-12">
              <Select label={'Doctors'} disabled list={list.Doctors?.map((doctor) => ({ label: doctor.name, value: doctor._id }))} register={register('doctorId')} />
            </div>

          <div className="col-md-12">
            <Select label={'Firm'} disabled list={firms} register={register('firm')} />
          </div>

          <div className="col-md-6">
            <Select label={'Payment Mode'} list={[ ...paymentModes, { lable: "Follow Up", value: "followUp" }]} register={register('mode')} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <button className='w-100 btn btn-primary rounded'>Edit</button>
          </div>
          <div className="col-md-6">

            <button className="btn btn-primary w-100 rounded" type="submit" disabled={isSubmitLoading}>
              <Spinner loading={isSubmitLoading} >
                Save Appointment
                </Spinner>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
