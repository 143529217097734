import React from "react";

const InputBox = ({label, type='text',  placeholder, register = {}, ...props}) => {
  return (
      <div className="mb-3">
        {label && <label className="form-label">{label}</label>}
        <input
          type={type}
          className="form-control"     
          placeholder={placeholder}
          { ...register }
          {...props}

        />
    </div>
  );
};

export default InputBox;
