import { useCallback, useState } from "react"

export const useDebounce = ( callback, delay = 1500, dependencies = []) => {
    let timer = null
    const [ isLoading, setIsLoading ] = useState( false )
    const [ error, setError ] = useState('')

    const fn = useCallback(( ...args ) => {
        setIsLoading( true )
        clearTimeout(timer)
        
        timer = setTimeout(async () => {
            try{
                await callback( ...args )
            } catch(err){
                setError(err?.response?.data?.message || "Something Went Wrong!")
                console.log(err)
            }
            setIsLoading( false )
        }, delay)

    }, dependencies )

    return [ fn, isLoading, error ]
}