import { createContext, useContext, useEffect, useMemo, useState } from "react";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState({ isOpen: false, type: "default", content: "", icon: "" })
    const value = useMemo(() => [notification, setNotification], [notification])

    useEffect(() => {
        if (notification.isOpen) {
            setTimeout(() => setNotification({ ...notification, isOpen: false, }), 2500)
        }
    }, [notification.isOpen])

    return (

        <NotificationContext.Provider value={value} >
            <div className={`swal-container slide-animate  ${notification.isOpen ? "show" : ""}`}>
                <div className="bg-white rounded p-4 shadow-lg">
                    {notification.icon}

                    <h2 className="text-center">{ notification.content }</h2>
                </div>
            </div>
            {children}
        </NotificationContext.Provider>
    )
}

export const useNotification = () => {
    const [notification, setNotification] = useContext(NotificationContext)

    const success = (content = "") =>
        setNotification({
            isOpen: true,
            type: "success",
            content,
            icon: <div className="success-checkmark">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
        })

    const warning = (content = "") =>
        setNotification({
            isOpen: true,
            type: "warning",
            content,
        })

    const error = (content = "") =>
        setNotification({ 
            isOpen: true, 
            type: "error", 
            content,
            icon: <div className="d-flex justify-content-center mb-3">
                <div className="circle-border"></div>
                <div className="circle">
                    <div className="error"></div>
                </div>
            </div> 
        })

    const info = (content = "") =>
        setNotification({ isOpen: true, type: "info", content })

    return { success, warning, error, info }
}