import Avatar from '../assets/images/avatar.jpg';
import Logo from '../assets/images/logo.png';
import LoginBanner from '../assets/images/login-banner.png';
import { 
    faChartSimple, 
    faHospitalUser, 
    faDollarSign, 
    faFileWaveform, 
    faGear, faCalendarCheck, faThunderstorm,
    faPills,
    faSquarePlus,
    faUser,
    faHandHoldingMedical,
    faGears,
    faMoneyBill1Wave,
    faListCheck
} from '@fortawesome/free-solid-svg-icons'

export const avatar =  Avatar
export const logo = Logo
export const login_banner = LoginBanner


export const RC_MENU = {
    RC: [
        {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
        {id: "patients", name:"Patients", path:"/patients", icon: faHospitalUser },
        {id: "appointments", name:"Appointments", path:"/appointments", icon: faCalendarCheck },
        {id: "payments", name:"Payments", path:"/payments", icon: faDollarSign },
        {id: "report", name:"Reports", path:"/reports", icon: faFileWaveform },
        {id: "setting", name:"Setting", path:"/settings", icon: faGear },
        {id: "sample", name:"Sample", path:"/sample", icon: faGear },
        {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },
    ],

    SA: [
        {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
        {id: "users", name:"Users", path:"/users", icon: faUser },
        {id: "users", name:"Services", path:"/services", icon: faHandHoldingMedical },
        {id: "setting", name:"Setting", path:"/settings", icon: faGear },
        {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },

    ],

    DR : [
        {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
        {id: "appointments", name:"Tokens", path:"/tokens", icon: faCalendarCheck },

        {id: "appointments", name:"Appointments", path:"/appointments", icon: faCalendarCheck },
        {id: "patients", name:"Patients", path:"/patients", icon: faHospitalUser },
        {id: "payments", name:"Payments", path:"/payments", icon: faDollarSign },
        {id: "setting", name:"Setting", path:"/settings", icon: faGear },
        {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },
    ],

    JDR : [
        {id: "currentappointments", name:"Tokens", path:"/", icon: faCalendarCheck },
        {id: "appointments", name:"Appointments", path:"/appointments", icon: faCalendarCheck },
        {id: "patients", name:"Patients", path:"/patients", icon: faHospitalUser },
        {id: "setting", name:"Setting", path:"/settings", icon: faGear },
        {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },
    ],

    PH : [
        {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
        {id: "appointments", name:"Appointments", path:"/appointments", icon: faCalendarCheck },
        {id: "payments", name:"Payments", path:"/payments", icon: faDollarSign },
        {id: "medicines", name:"Medicines", path:"/medicines", icon: faPills },

        {id: "add-medicine", name:"Add Medicine", path:"/add-medicine", icon:faSquarePlus  },
        {id: "setting", name:"Setting", path:"/settings", icon: faGear },
        {id: "apitesting", name:"API Testing", path:"/apitesting", icon: faThunderstorm },
    ],

    AC : [
      {id: "dashboard", name:"Dashboard", path:"/", icon: faChartSimple },
      {id: "expances", name:"Expances", path:"/expances", icon: faDollarSign },
      {id: "earning", name:"Earning", path:"/earning", icon: faMoneyBill1Wave },
      {id: "setup", name:"Setup", path:"/setup", icon: faListCheck },
      {id: "setting", name:"Setting", path:"/settings", icon: faGear },





    ]
}


export const medicineTypes = [
    {label:"Injection", value:"injection", color: "red" },
    {label:"Tablet", value:"tablet", color: "green" },
    {label:"Capsule", value:"capsule", color: "pink" },
    {label:"Syrup", value:"syrup", color: "brown" },
    {label:"Cream", value:"cream" , color: "violet"},
    {label:"Lotion", value:"lotion", color: "yellow" },
    {label:"Powder", value:"powder", color: "grey" },
    {label:"Other", value:"other", color: "blue" },
]

export const paymentModes = [
    { label: "Cash", value: 'cash' }, 
    { label: "Online", value: "online" },
]

export const medicineStatus = [
    { label: "In Stock", value: "inStock" },
    { label: "Low Stock", value: "lowStock" },
    { label: "Out of Stock", value: "outOfStock" },
    { label: "Expired", value: "expired" },
  ]

export  const  diseases = [
    {
      label: "Hypertension",
      value: "hypertension",
      medicines: [
        { label: "Amlodipine", value: "amlodipine", stock: 120, type: "tablet" },
        { label: "Lisinopril", value: "lisinopril", stock: 200, type: "capsule" },
        { label: "Losartan", value: "losartan", stock: 150, type: "tablet" },
        { label: "Hydrochlorothiazide", value: "hydrochlorothiazide", stock: 180, type: "syrup" }
      ]
    },
    {
      label: "Diabetes",
      value: "diabetes",
      medicines: [
        { label: "Metformin", value: "metformin", stock: 300, type: "tablet" },
        { label: "Glipizide", value: "glipizide", stock: 220, type: "tablet" },
        { label: "Insulin", value: "insulin", stock: 80, type: "injection" },
        { label: "Pioglitazone", value: "pioglitazone", stock: 130, type: "capsule" },
        { label: "Sitagliptin", value: "sitagliptin", stock: 100, type: "tablet" }
      ]
    },
    {
      label: "Asthma",
      value: "asthma",
      medicines: [
        { label: "Albuterol", value: "albuterol", stock: 90, type: "inhaler" },
        { label: "Fluticasone", value: "fluticasone", stock: 60, type: "injection" },
        { label: "Montelukast", value: "montelukast", stock: 150, type: "tablet" }
      ]
    },
    {
      label: "Allergies",
      value: "allergies",
      medicines: [
        { label: "Cetirizine", value: "cetirizine", stock: 200, type: "tablet" },
        { label: "Loratadine", value: "loratadine", stock: 210, type: "syrup" },
        { label: "Fexofenadine", value: "fexofenadine", stock: 180, type: "capsule" },
        { label: "Diphenhydramine", value: "diphenhydramine", stock: 160, type: "tablet" }
      ]
    },
    {
      label: "Arthritis",
      value: "arthritis",
      medicines: [
        { label: "Ibuprofen", value: "ibuprofen", stock: 300, type: "tablet" },
        { label: "Naproxen", value: "naproxen", stock: 250, type: "capsule" },
        { label: "Methotrexate", value: "methotrexate", stock: 110, type: "injection" },
        { label: "Celecoxib", value: "celecoxib", stock: 100, type: "tablet" }
      ]
    },
    {
      label: "Migraine",
      value: "migraine",
      medicines: [
        { label: "Sumatriptan", value: "sumatriptan", stock: 90, type: "tablet" },
        { label: "Rizatriptan", value: "rizatriptan", stock: 80, type: "capsule" },
        { label: "Ergotamine", value: "ergotamine", stock: 70, type: "powder" },
        { label: "Propranolol", value: "propranolol", stock: 120, type: "tablet" }
      ]
    },
    {
      label: "Depression",
      value: "depression",
      medicines: [
        { label: "Sertraline", value: "sertraline", stock: 200, type: "tablet" },
        { label: "Fluoxetine", value: "fluoxetine", stock: 150, type: "capsule" },
        { label: "Escitalopram", value: "escitalopram", stock: 130, type: "tablet" },
        { label: "Bupropion", value: "bupropion", stock: 100, type: "tablet" },
        { label: "Venlafaxine", value: "venlafaxine", stock: 140, type: "capsule" }
      ]
    },
    {
      label: "Anxiety",
      value: "anxiety",
      medicines: [
        { label: "Alprazolam", value: "alprazolam", stock: 90, type: "tablet" },
        { label: "Diazepam", value: "diazepam", stock: 85, type: "capsule" },
        { label: "Buspirone", value: "buspirone", stock: 110, type: "tablet" }
      ]
    },
    {
      label: "Cholesterol",
      value: "cholesterol",
      medicines: [
        { label: "Atorvastatin", value: "atorvastatin", stock: 200, type: "tablet" },
        { label: "Simvastatin", value: "simvastatin", stock: 180, type: "tablet" },
        { label: "Rosuvastatin", value: "rosuvastatin", stock: 150, type: "tablet" },
        { label: "Ezetimibe", value: "ezetimibe", stock: 130, type: "capsule" }
      ]
    },
    {
      label: "Osteoporosis",
      value: "osteoporosis",
      medicines: [
        { label: "Alendronate", value: "alendronate", stock: 120, type: "tablet" },
        { label: "Risedronate", value: "risedronate", stock: 100, type: "tablet" },
        { label: "Ibandronate", value: "ibandronate", stock: 90, type: "tablet" },
        { label: "Calcitonin", value: "calcitonin", stock: 60, type: "injection" },
        { label: "Raloxifene", value: "raloxifene", stock: 80, type: "tablet" }
      ]
    }
  ];

export const firms = [
  {label:"Narayan Medical Care", value:"Narayan Medical Care"},
  {label:"Shubh Speciality Center", value :"Shubh Speciality Center"},
]
  
  
  