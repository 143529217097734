import React from "react";

const DataList = ({ label, placeholder, type = 'text', register = {}, list, ...props }) => {
  return (
    <div>
      <label className="form-label">{label}</label>
      <input
        type={type}
        className="form-control "
        placeholder={placeholder}
        list="City"
        {...register}
        {...props}
      />
      <datalist id="City">
        {list.map((value, i) => (<option value={value} key={i} />))}
      </datalist>
    </div>
  );
};

export default DataList;
