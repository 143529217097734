import InputBox from "../../../components/Elements/InputBox";
import RadioButton from "../../../components/Elements/RadioButton";
import Spinner from '../../../components/Elements/Spinner';
import ErrorMessage from '../../../components/Elements/ErrorMessage';

import DataList from "../../../components/Elements/DataList";
import { useForm } from "react-hook-form";
import Select from "../../../components/Elements/Select";

import axios from "../../../axios";

import Search from "../Search";
import { useDebounce } from '../../../hooks/useDebounce';

import { useDispatch } from "react-redux";
import { setPatient, setTab } from "../../../store/reducers/reception"; 

const Appointment = () => {
  const dispatch = useDispatch()
  
  
  const { handleSubmit, register, watch } = useForm({
    onChange: true,
    defaultValues: { appointmentType: "new" },
  });


  const [ onSubmit, isSubmitLoading, error ] = useDebounce(async (data) => {
      const response = await axios.post("reception/patient", data )
      dispatch(setPatient(response.data.patient))
      dispatch(setTab("payment"))
  })


  return (
      <div className="row mb-5">
        <div className="col-md-6">
          <RadioButton value={"new"} label={"New "} register={register('appointmentType')} />
        </div>
        <div className="col-md-6">
          <RadioButton value={"renew"} label={"Renew"} register={register('appointmentType')} />
        </div>
        {watch('appointmentType') === 'new' ? (
          <>
              <form onSubmit={handleSubmit(onSubmit)}>

            <div className="col-md-12">
              <InputBox label={"Patients Name *"} placeholder={"Patients Name"}  register={register('name', {required: true})} />
            </div>
            <div className="col-md-12 mb-3">
              <div>
                <h6 className="my-3">Address</h6>
                <div className="col-md-12">
                  <InputBox label={"Area"} placeholder={"Area"} register={register('address.area')} />
                </div>
                <DataList list={['Aligarh', 'Mumbai']} label={"City *"} placeholder={"City"} register={register('address.city', {required: true})} />
              </div>
            </div>
            <div className="row">

            <div className="col-md-6">
              <InputBox label={"Age *"} placeholder={"Age"} type="number" register={register('age', {required: true})} />
            </div>
            <div className="col-md-6">
              <Select label={'Gender'} list={[{label:"Male", value:'M'}, {label:"Female", value:"F"}, {label:"Other", value:"O"}]} register={register('gender')} />
            </div>
            </div>

            <div className="col-md-12">
              <InputBox label={"Mobile Number *"} type="number" maxLength={10} placeholder={"Mobile Number"} register={register('phone', {required: true})} />
            </div>
            <div className="col-md-12 my-3">
              <ErrorMessage error={error} />
              <button className="btn btn-primary w-100 rounded" type="submit" disabled={isSubmitLoading}>
                <Spinner loading={isSubmitLoading} >
                  Create Patient
                </Spinner>
              </button>
            </div>
            </form>

          </>
        ) : (
          <div className="col-12">
            <Search />
          </div>
        )}
      </div>
  );
};

export default Appointment;
