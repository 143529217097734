import { createSlice } from "@reduxjs/toolkit"


const commonSlice = createSlice({
    name: "common",

    initialState: {
        offcanvas: false,
        sidebar: true,
        mobileSidebar: false,
        isOnline: window.navigator.onLine
    },

    reducers: {
        
        offcanvasToggle: ( state, action ) => {
            state.offcanvas = !state.offcanvas
        },

        toggleSidebar: ( state, action ) => {
            state.sidebar = !state.sidebar
        },

        toggleMobileSidebar: ( state, action ) => {
            state.mobileSidebar = !state.mobileSidebar
        },

        setIsOnline: ( state, action ) => {
            state.isOnline = action.payload
        }

    }
})

export const { offcanvasToggle, toggleSidebar, toggleMobileSidebar, setIsOnline } = commonSlice.actions;
export default commonSlice.reducer;