import { useEffect, useState } from "react"
import { setIsOnline } from "../store/reducers/common"
import { useDispatch, useSelector  } from 'react-redux'


export default ({ children }) => {
    const dispatch = useDispatch()    
    const isOnline = useSelector( state => state.common.isOnline )
    const [ show, setShow ] = useState(true)

    useEffect(() => {
        window.onoffline = (e) => {
            setShow(false)
            dispatch(setIsOnline(false))
        }
        
        window.ononline = (e) => {
            dispatch(setIsOnline(true))
            setShow(true)

            const timeout = setTimeout(() => {
                dispatch(setIsOnline(true))
                setShow(false)
                clearTimeout(timeout)
            }, 1500)
        }

    }, [])

    return(
        <>
            { show && !isOnline && <div className={`position-fixed w-100  text-center ${ !isOnline ? "bg-danger" : "bg-success" }`} style={{ zIndex: 10001, top: 0 }}>{ isOnline ? "Online" : "Offline"  }</div>}
            <main className={`${ show && !isOnline && "pt-4" }`}>
                { children }
            </main>
        </>
    )
}