import { lazy } from "react"
import Loadable from '../components/Loadable';
import Table from "../components/Elements/Table"

const page = (page) =>
    Loadable(
        lazy(
            () => import(`../pages/${page}`)
        )
    )

/**************** Reception Pages *************************** */
export const RC_Dashboard = page("Reception/Dashboard")
export const RC_Reports = page("Reception/Reports")
export const GenerateReceipt = page("Reception/GenerateReceipt")
/************************************************************ */


/****************** Common Pages ******************************/
export const Setting = page("Setting")
export const Patients = page("Patients")
export const Payments = page("Payments")
export const Appointments = page("Appointments")
export const Sample = page("Sample")
export const Homepage = page('Homepage')
export const APITesting = page('APITesting')
export const PatientProfile = page('Templates/AppointmentCard')
/************************************************************ */


/****************** Doctors Pages ******************************/
export const DR_Dashboard = page("Doctor/Dashboard")
export const DR_CurrentTokens = page("Doctor/PatientsReview")
export const DR_Perscription = page('Doctor/Perscription')
/**************************************************************/


/****************** Pharmacist Pages ******************************/
export const PH_Dashboard = page("Pharmacist/Dashboard")
export const PH_AddMedicine = page("Pharmacist/AddMedicine")
export const PH_Medicines = page("Pharmacist/Medicines")
export const PH_GenerateInvoice = page("Pharmacist/GenerateInvoice")
export const PH_Invoice = page("Templates/MedicineInvoice")
/**************************************************************/

/******************************Super Admin********************************/
export const SA_Dashboard = page('SuperAdmin/Dashboard')
export const SA_Users = page('SuperAdmin/Users')
export const SA_Services = page('SuperAdmin/Services')
/**************************************************************/


/******************************Accountant********************************/

export const AC_DashBoard= page("Accountant/DashBoard")
export const AC_Earning= page("Accountant/Earning")
export const AC_Expances= page("Accountant/Expances")
export const AC_Categories= page("Accountant/Categories")
export const AC_Setup= page("Accountant/SetUp")


/**************************************************************/



