import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';

import AuthRoutes from "./AuthRoutes";
import AppRoutes from './AppRoutes';

import { useSelector } from 'react-redux'
import PageLoader from '../layouts/PageLoader'


// **************** Routes Rendrer **************** //

export default () => {
    const auth = useSelector(state => state.auth)
    const role = auth.user?.role?.role
    const router = createBrowserRouter([
        auth.isLoggedIn
            ? AppRoutes(role)
            : AuthRoutes
        ],
        { basename: '/' })

    return (
        <Suspense fallback={<PageLoader />}>
            <RouterProvider router={router} />
        </Suspense>
    )
}