import './socket';

import { Provider as ReduxProvider } from 'react-redux'
import RootRouter from "./routes"

import { store } from './store'
import { NotificationProvider } from './contexts/Notification'

export default () => {
    return (
        <ReduxProvider store={store}>
            <NotificationProvider>
                <RootRouter />
            </NotificationProvider>
        </ReduxProvider>
    )
}